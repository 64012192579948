<template>
    <nuxt-link
        :to="$prismic.linkResolver(card)" 
        :class="type"
        class="container-box"
    >
        <div class="container-box__inner">
            <div>
                <prismic-image
                    :img="card.data.image"
                    w="280"
                    h="175"
                    class="card-image"
                />

                <card-rating
                    :rating="card.data.core_api.ratings.average_score"
                    :card="card"
                    :size="cardRatingSize"
                />
            </div>
        </div>

        <div class="container-box__inner">
            <div class="inner">
                <span class="font-header">{{ card.data.title }}</span>
                <span
                    v-if="uspText"
                    class="usp-text font-text"
                >
                    {{ uspText }}
                </span>

                <div class="btn btn-md btn-reset">
                    {{ buttonText }}
                    <i class="fa-solid fa-arrow-right" />
                </div>
            </div>
        </div>
    </nuxt-link>
</template>

<script>
import CardRating from '@/components/features/CardRating.vue';
export default {
    components: { CardRating },
    props: {
        document: {
            type: Object,
            required: true,
            default: undefined
        },
        type: {
            type: String,
            required: false,
            default: 'default'
        },
        uspText: {
            type: String,
            required: false,
            default: undefined
        }
    },
    computed: {
        card() {
            return this.document;
        },

        cardRatingSize() {
            if (this.type === 'highlighted') {
                return 'lg'; 
            }
            return 'sm';
        },

        buttonText() {
            return this.$translate('cta_read_review', 'Läs recension');
        }
    }
};
</script>

<style lang="scss" scoped>
    .container-box {
        text-align: center;

        .card-image {
            @include size(160px,100px);
            max-width: 100%;
            margin-bottom: 0.25rem;
        }

        .usp-text {
            @include font-size(14px);
        }

        &__inner {
            @include spacing(padding,6);
            &:first-child { background: $graybg; }
        }
    }

    // Layouts
    .container-box.default {
        @include flex(center,stretch);

        @include device(pad) {
            .card-image {
                @include size(120px,auto);
            }
        }

        .container-box__inner:first-child {
            @include flex;
        }

        .container-box__inner:last-child {
            .inner {
                @include grid(1,10px);
                text-align: left;
                .btn { text-align: left; }
            }
        }
    }

    .container-box.highlighted::v-deep {
        .card-rating.lg .card-rating__star {
            @include size(20px, 20px);
        }
        @include device(pad) {
            .card-image {
                @include spacing(margin,3,bottom);
                @include size(280px,175px);
            }
            .card-rating.lg .card-rating__star {
                @include size(35px, 35px);
            }
        }

        .container-box__inner {
            @include flex;
        }

        .container-box__inner:last-child {
            @include flex(start, center);
            .inner {
                @include grid(1,10px);
            }
            .inner, .btn {
                text-align: left;
            }
            
            @include device(pad) {
                @include flex;
                .inner, .btn {
                    text-align: center;
                }
            }
        }
    }
    .container-box:hover {
        .container-box__inner:last-child {
            .inner {
                .btn {
                    color: $green;
                }
                i {
                    margin-left: 15px;
                }
            }
        }
    }
</style>
